<template>
	<div class="fill-height d-flex flex-column">
		<AppBar disable-help :to-page="back" color="primary100" />
		<v-app-bar dense color="primary100" flat>
			<v-app-bar-title
				shrink-on-scroll
				class="flex text-center font-weight-black"
				>{{ $t($route.meta.title) }}</v-app-bar-title
			>
		</v-app-bar>
		<div class="fill-height">
			<router-view />
		</div>
	</div>
</template>

<script>
import AppBar from "@/components/AppBar";
import { mapGetters } from "vuex";
export default {
	components: {
		AppBar
	},
	computed: {
		...mapGetters("service", ["getBasePath"]),
		back: vm => vm.getBasePath(vm.$route.meta.back)
	}
};
</script>
